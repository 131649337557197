import styled from "styled-components";

const ModalStyles = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 20px; */
  position: relative;
  box-sizing: border-box;
  overflow: visible;
  /* background-color: #00B5BC; */

  @media only screen and (max-width: 600px) {
    padding: 0px 20px 30px 20px;
  }

  p {
    font-family: open-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
  }
  
  .body {
    overflow-y: scroll;
    /* overflow-x: hidden; */
    height: calc(100% - 21px);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;

    @media only screen and (max-width: 600px) {
      margin-top: 20px;
      height: calc(100% - 10px);
    }

    h1 {
      /* font-family: ${(props) => props.theme.font}; */
      font-family: jubilat, serif;
      font-weight: 900;
      font-style: normal;
      /* text-transform: uppercase; */
      /* -webkit-text-stroke: 3px #2fa3a3; */
      /* text-shadow: 0.08rem 0.08rem 0 #05777c; */
      /* text-shadow: 2px 2px 8px #05777c; */
      text-shadow:
      -8px -8px 0 #31a6ab,
      0   -8px 0 #31a6ab,
      8px -8px 0 #31a6ab,
      8px  0   0 #31a6ab,
      8px  8px 0 #31a6ab,
      0    8px 0 #31a6ab,
      -8px  8px 0 #31a6ab,
      -8px  0   0 #31a6ab;
      /* color: ${(props) => props.theme.colours.primary}; */
      color: white;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 0px;
      font-size: 3.8em;
      letter-spacing: 0px;
      line-height: 0.9;

      @media only screen and (max-width: 600px) {
        font-size: 42px;
        line-height: 42px;
        padding-bottom: 8px;
      }
    }

    @media only screen and (max-width: 600px) {
      .header {
        margin-top: 20px;
      }
    }

    div.header {
      margin-top: 10px;
    }

    div.alison-video {
      margin-top: 100px;
      @media only screen and (max-width: 600px) {
        margin-top: -25px;
        }
    }

    .address {
      /* margin-bottom: 5px; */
      text-align: center;
      font-family: open-sans, sans-serif;
      font-weight: 400;
      font-style: normal;
      margin-bottom: 8px;
      margin-top: 8px;
      a {
        color: white;
        font-size: 18px;
        text-decoration: none;
        transition-duration: 0.4s;
        letter-spacing: 0px;
        font-family: open-sans, sans-serif;
        font-weight: 600;
        font-style: normal;
        :hover {
          color: #411665;
        }
        @media only screen and (max-width: 600px) {
          font-size: 18px;
        }
      }
    }
    .categoryname {
      font-size: 11px;
      font-family: open-sans, sans-serif;
      font-weight: 400;
      font-style: normal;
      color: #ffe604;
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 10px;
      @media only screen and (max-width: 600px) {
        h2 {
          line-height: 20px;
          font-size: 14px;
        }
      }
    }

    p.yt-video {
      margin-top: 40px;
    }

    div.youtube {
      text-align: center;
      /* margin-bottom: 20px; */
    }

    div.intro-modal {
      padding-bottom: 20px !important;
    }

    div.intro-copy {
      margin-top: 30px;
    }

    @media only screen and (max-width: 600px) {
      div.intro-copy {
      margin-top: 20px;
    }
    }

    .intro {
      font-family: "Open Sans";
      color: grey;
      text-align: center;
      line-height: 1.4;
      a {
        color: white;
        /* font-weight: 600px; */
        text-decoration: underline;
        /* font-weight: 600;
        font-size: 21px; */
      }
      a:hover {
        color: #411665;
        transition: 0.2s;
      }
    }
    @media only screen and (max-width: 600px) {
      p {
        font-size: 18px;
        margin-bottom: 15px;
      }
    }

    p.hashtag {
      font-weight: 600;
      font-size: 21px;
      display: inline;
      margin-left: 20px;
    }

    button.visit_website {
      font-weight: 500;
      font-size: 16px;
      padding: 10px 30px;
      margin-top: 20px;
      margin-bottom: 20px;
      background: #411665;
      color: #ffffff;
      border: none;
      /* border: 2px solid #ffffff; */
      border-radius: 5px;
      -webkit-transition-duration: 0.4s;
      transition-duration: 0.4s;
      outline: none;
      cursor: pointer;

      :hover {
        background-color: #f14d68;
        color: #ffffff;
        border: none;
      }
    }

    .iconify {
      padding: 0 3px;
      color: white;
      width: 24px;
      height: 24px;
      transition-duration: 0.4s;
      :hover {
        color: #ffe604;
      }
      @media only screen and (max-width: 600px) {
        margin-top: 10px;
      }

      &.facebook {
        width: 21px;
        height: 23px;
      }
    }

    .modal_underline {
      border-bottom: 7px solid #f14d68;
      margin-bottom: 20px;
      font-size: 1.2em;
      margin-left: 200px;
      margin-right: 200px;
      @media only screen and (max-width: 600px) {
        border-bottom: 4px solid #f14d68;
        margin-bottom: 15px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    .carousel-root .slide {
      & > div {
        height: 100%;
        /* @media only screen and (max-width: 600px) {
          height: 280px;
        } */

        & > img {
          height: 100%;
          object-fit: cover;
          @media only screen and (max-width: 600px) {
            height: 280px;
          }
        }
      }
    }
    .slider-wrapper {
      width: 100%;
    }

    .singleImage img {
      max-height: 340px;
      width: 100%;
      object-fit: cover;
      -webkit-box-shadow: 5px 5px 14px -1px rgb(0 0 0 / 15%);
      box-shadow: 5px 5px 14px -1px rgb(0 0 0 / 15%);
      border: solid white 10px;
      border-radius: 5px;

      @media only screen and (max-width: 600px) {
        height: 280px;
        width: 93%;
        padding-bottom: 0px;
      }
    }
    .carousel.carousel-slider {
      height: 340px;
      -webkit-box-shadow: 5px 5px 14px -1px rgb(0 0 0 / 15%);
      box-shadow: 5px 5px 14px -1px rgb(0 0 0 / 15%);
      /* border: 5px solid white; */
      /* border-radius: 15px; */
      
      @media only screen and (max-width: 600px) {
        height: 280px;
      }
    }

    .carousel {
      margin: 0px 0px 25px;

      @media only screen and (max-width: 600px) {
        margin: 0px 0px 15px;
      }
    }

    .dot {
      background-color: white;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 80);
    }
    .dot:hover {
      opacity: 1;
      background: ${(props) => props.theme.colours.primary};
    }
    .dot:focus {
      outline: none;
    }

    .carousel-status {
      display: none;
    }

    .thumbs-wrapper {
      display: none;
    }
  }
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #411665;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #411665;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: rgba(65, 22, 101, 0.2);
  }

  .close_button {
    background-color: transparent;
    border: 0;
    position: absolute;
    right: -14px;
    top: -6px;

    @media only screen and (max-width: 600px) {
      top: -15px;
      right: 0px;
    }
  }

  .close_button:hover {
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -ms-transition: 0.7s;
    -o-transition: 0.7s;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: 50% 50%;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }

  .material-icons {
    background-color: transparent;
    font-size: 26px;
    color: white;
  }

  .brand {
    position: absolute;
    width: 760px;
    height: 160px;
    top: -50px;
    left: 10px;
    transform: rotateZ(-0deg);
    -webkit-transform: rotateZ(-0deg);
    z-index: 20;

    @media only screen and (max-width: 600px) {
      top: -52px;
      left: -6px;
      width: 330px;
      height: 100px;
      /* img {
        width: 330px;
        height: 100px;
      } */
    }
  }

  .inner_body {
    height: 100%;
    width: 90%;
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
      width: 100%;
      margin-top: 20px;
    }
  }
  .body::-webkit-scrollbar {
    width: 7px;
    padding-right: 5px;    
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .body::-webkit-scrollbar-track {
    background: #e5e5df;
    border-radius: 7px;
    margin-top: 40px;
  }
  .body::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colours.primary};
    border-radius: 7px;
  }
`;

export default ModalStyles;
