import { createGlobalStyle } from "styled-components";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  html {
    width: 100%;
    height: 100%;
    overscroll-behavior: none;
  }

  body {
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.colours.background};
    overflow-y: hidden;
  }

  html, body {
    overscroll-behavior-y: contain;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  p {
    color: white;
    font-family: open-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .ReactModal__Content {
    background-color: #00B5BC;
    overflow: scroll;
  }

  .resetButton:hover {
    background-color: rgb(255, 230, 4);
    }

    .panZoomMap, .panZoomMap * {
      touch-action: none;
    }

  .zoomButtons {
    width: 42px;
    height: 84px;
    /* border-radius: 2px; */

      .zoomButton {
        height: 40px;
        /* background-color: #fff; */
        /* margin-bottom: 2px; */
        /* border-radius: 5px; */
        &:hover {
          background-color: #99E1E4;
          border:1px solid #99E1E4;

          i {
            color: ${(props) => props.theme.colours.primary};
          }
        }

        i {
          width: 100%;
          text-align: center;
          line-height: 1.6;
          cursor: pointer;
        }
      }
  }

  @media only screen and (max-width: 600px) {
    .zoomButtons {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      position: fixed;
      bottom: -3px;
      right: 39px;
      height: 84px;
      display: none;

      .zoomButton {
        height: 40px;

        i {
          transform: rotate(90deg) translateX(2px);
          -webkit-transform: rotate(90deg) translateX(2px);
        }
      }
    }

    .resetButton {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      position: fixed;
      bottom: 17px;
      right: 120px;
      border-radius: 5px;
      display: none;
    }
  }

  @media only screen and (max-width: 750px) {
    .ReactModal__Content {
      width:85%!important;
      background-color: #00B5BC !important;
    }

    /* .brand {
      width:50px!important;
      height:50px!important;
    } */


  }
  .intro::-webkit-scrollbar {
    width: 7px;
    padding-right: 5px;    
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .intro::-webkit-scrollbar-track {
    background: #e5e5df;
    border-radius: 7px;
    margin-top: 40px;
  }
  .intro::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colours.primary};
    border-radius: 7px;
  }

  #intro-panel-header{
    position: relative; 
    z-index:200;    
    left: -751px;
    top: -1736px;
    width: 1237px;
  }
 
  #intro-panel {
    .introbox{
      position: relative;
    z-index: 100;
    /* background: url(/natwestMap/static/media/modalTexture.a7f2e86d.png) rgba(0,0,0,0); */
    background: url(/natwestMap/static/media/bgtexture-intro.e7f45247.png) rgba(0,0,0,0);
    overflow: visible;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* border-radius: 4px; */
    outline: none;
    padding: 187px 35px 145px 35px;
    width: 590px;
    height: 790px;
    left: -450px;
    top: -1683px;
    overflow-y: hidden;
    transform: scale(1.75);
    -webkit-transform: scale(1.75);

      .intro {
        overflow: hidden; 
        /* height:810px; */
        margin-top: -80px;

        .alison-video {
          margin: 70px auto 0;  
          position:relative; 
          left: 14px;
          
          iframe {
            border: solid white 10px; 
            border-radius: 5px;
          }
        }

        .alison-bumper {
          margin-top: -10px;
          span {
            background-color: #411665;
            padding: 5px;
          }

          h2 {
            color: white;
            font-family: open-sans, sans-serif;
            font-weight: 600;
            font-style: normal;
          }
        }

        .intro-copy {
          font-size: 24px;
          line-height: 30px;

          a {
            color:#FFFFFF;
          }
        }
      }
    }

  }
  .padController {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    
    @media only screen and (max-width: 600px) {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

`;

export default GlobalStyle;
