import styled from "styled-components";

const HeaderStyles = styled.div`
  position: fixed;
  right: 0;
  top: -1px;
  bottom: 0;
  left: auto;
  width: 120px;
  background-color: #411665;
  /* border-left: 2px solid black; */
  z-index: 10;

  p{
    font-family: open-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .logo {
    /* padding-right: 20px; */
    width: 70%;
    z-index: 1;
    left: 20px;
    bottom: 10px;
    /* padding: 10px; */
    /* background: #1258a5; */
  }
  img {
    position: absolute;
    bottom: 1px;
    cursor: pointer;
  }

  .mobileMenu {
    display: none;
    background: transparent;
    border: none;
    color: white;
    position: fixed;
  }

  @media only screen and (max-width: 600px) {
    /* border-top: 2px solid black; */
    background-color: #411665;
    top: ${(p) => (p.isHamburgerOpen ? 0 : "calc(100% - 80px)")};
    transition: 0.3s ease-in-out top;
    /* top: calc(100% - 80px); */
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 101%;

    & > div {
      opacity: ${(p) => (p.isHamburgerOpen ? 1 : 0)};
      transition-delay: 0.3s;
      transition: 0.3s ease-in-out opacity;
    }

    .mobileMenu {
      display: block;
      /* margin-left: 20px; */
      position: absolute;
      right: 20px;
      margin-top: 20px;
      z-index: 1;

      .material-icons {
        font-size: 40px;
        /* position: absolute;
        right: 30px;
        margin-top: 20px; */
      }
    }

    .logo {
      /* padding-left: 20px; */
      content:url("https://www.reachcreativestudio.com/natwestMap/natwestLogoMixedLong.svg");
      /* content:url("../images/natwestgroup-white-2.png"); */
      background-color: 1258a5;
      width: 50%;
      top: 12px;
      left: 15px;
    }

    img {
      /* padding-left: 20px; */
    }
  }
`;

export default HeaderStyles;
