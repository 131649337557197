import React, { useState, useEffect } from "react";
import PanZoom from "./panzoom-controls/Controls";
import MapStyles from "../styles/MapStyles";
import Point from "./Point";
import PointHtml from "./PointHtml";
import Modal from "./modal-controls/Modal";
import MapImage from "../images/natwest-map.jpg";
import points from "../utils/mapPoints";
import MapContainer from "../styles/MapContainerStyles";
import ReactTooltip from "react-tooltip";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const allCats = points
  .map((p) => {
    if (typeof p.category !== "string" && p.category !== null) {
      return [...p.category];
    }
    return p.category;
  })
  .flat()
  .filter((p) => p !== null)
  .filter(onlyUnique);

const defaultSelectedCategories = ["pin"];



const Map = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContents, setModalContents] = useState({});
  const [activeCategories, setActiveCategories] = useState(
    defaultSelectedCategories
  );

  useEffect(() => {
    setModalContents({
      title: points[0].name,
      content: points[0].content,
      brand: points[0].brand,
      carouselContents: points[0].carousel,
    });
    // setModalOpen(true);
  }, []);

  return (
    <MapContainer>
      <PanZoom
        // boundaryRatioVertical={0.8}
        // boundaryRatioHorizontal={0.8}
        minZoom={0.2}
        // maxZoom={0.6}
        // autoCenter
        autoCenterZoomLevel={0.17}
        // enableBoundingBox
        categories={allCats}
        activeCategories={activeCategories}
        setActiveCategories={setActiveCategories}
        defaultCats={defaultSelectedCategories}

      >
        <MapStyles bgImg={MapImage}>
          {/* {points.map((point) => (
            <Point
              key={point.name}
              openModal={setModalOpen}
              modalContents={setModalContents}
              activeCategories={activeCategories}
              {...point}
            />
          ))} */}
           {points.map((point) => (
            <PointHtml
              key={point.name}
              openModal={setModalOpen}
              modalContents={setModalContents}
              activeCategories={activeCategories}
              displayHtml={points[0].html}
              {...point}
            />
          ))}
        </MapStyles>
      </PanZoom>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        html={modalContents}
        modalWidth={800}
        modalHeight={638}
      />
    </MapContainer>
  );
};

export default Map;
