import styled from 'styled-components';

const PageStyles = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overscroll-behavior: none;
  overflow: hidden;
`;

export default PageStyles;