// import background from "../images/modalTexture.png";
import background from "../images/bgtexture-intro.png";
import introHeader from "../images/headline-text2.svg";
export default [
  /**************************************/
  /**************************************/
  /************ Intro Modal** ***********/
  /**************************************/
  /**************************************/
  {
    // ytvideo: `<iframe width="700" height="394" src="https://www.youtube.com/embed/0tPfLvsnp-w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border: solid white 10px; border-radius: 5px;"></iframe>`,
    name: "",
    position: {
      x: 88,
      y: 1870,
    },
      transform: {
      width: 2410,
      height: 2250,
    },
    content: `
    <img src="${introHeader}" id="intro-panel-header" />
  
    <div id="intro-panel">
      <div  class="introbox"">
        <div>
          <div class="intro">
            <div class="alison-video" >
              <iframe width="90%" height="300" src="https://www.youtube.com/embed/0tPfLvsnp-w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="alison-bumper">
            <h2><span>Alison Rose, CEO of NatWest Group</span></h2>
            </div>
            <div class="intro-copy">
            <p>We’ve set ourselves a big ambition to be the leading bank in the UK helping to address climate change. That’s why we’re making our own operations climate positive by 2025 and at least halving the climate impact of our financing by 2030.<br></p>
            <p>And we’re helping families, communities and businesses go greener too with tools like our carbon tracking banking app with CoGo and our <a href="https://www.natwestgroup.com/news/2021/03/natwest-group-and-microsoft-join-forces-to-support-businesses.html" target="_blank" >‘Cut the Carbon’</a> project with Microsoft, which aims to help SMEs reduce their carbon emissions.</p>
            <p>We all know how important it is to start making changes and there are some amazing people across the UK who are already leading the way that we’ve been proud to support and work with.<br>
            You can check out some of their stories on this map.</p>
            

            </div>
          </div>
        </div>
      </div>
    </div>

    `,
    icon: "natwest-gogreen.svg",
    brand: "intro-headline.svg",
    category: null,
  },

  {
    name: "right panel",
    position: {
      x: 1906,
      y: 306,
    },
    transform: {
      width: 1010,
      height: 1020,
    },
    icon: "doMore.svg",
    // brand: "natwest-white.svg",
    category: null,
  },

  /**************************************/
  /**************************************/
  /****** London and South East *********/
  /**************************************/
  /**************************************/

  {
    name: "CoGo",
    // position: {
    //   x: 3611,
    //   y: 4046,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1804,
      y: 2026,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://cogo.co/",
    content: `
      <div class="intro">
      <p>It calculates your personalised carbon footprint in real-time, linked directly to your spending transactions and lifestyle choices.This means users can match their spending with their values for people and the planet.</p>
      <p>In partnership with CoGo, we earlier this year announced the introduction of a new carbon footprint tracking feature for our mobile app to help customers reduce the climate impact of their spending.</p>
      <p>The new feature allows customers to see the CO2 emissions associated with their daily spending, as well as providing hints and tips on how to go greener and resources for doing so. </p>
      </div>
    `,
    icon: "eco-business2.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    carousel: [
      {
        type: "image",
        content: "londonSE/coGo.jpg",
      },
    ],
    link: {
      text: "London",
      url: "https://www.google.com/maps/place/London/@51.528308,-0.3817765,10z/data=!3m1!4b1!4m5!3m4!1s0x47d8a00baf21de75:0x52963a5addd52a99!8m2!3d51.5073509!4d-0.1277583",
    },
  },

  {
    name: "Ethical Reading",
    // position: {
    //   x: 3308,
    //   y: 3973,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1648,
      y: 1987,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.ethicalreading.org.uk/",
    content: `
      <div class="intro">
      <p>Ethical Reading is a not-for-profit social enterprise dedicated to making Reading a better place to live and work by helping organisations become more ethical. </p>
      <p>One of the key parts of their vision is encouraging and supporting businesses to have a positive impact on society and the environment. And longer term they want to spark an ethical cities movement across the UK encouraging other towns and cities to follow Reading’s lead.</p>

      <p>NatWest is working with Ethical Reading and other climate organisations in the city like the Reading Climate Change Partnership to support Reading reach its goal of being a net zero, climate change.</p>

      </div>
    `,
    icon: "eco-business.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    carousel: [
      {
        type: "image",
        content: "londonSE/ethicalReading.jpg",
      },
    ],
    link: {
      text: "Reading",
      url: "https://www.google.com/maps/place/Reading/@51.4515049,-1.0311529,13z/data=!3m1!4b1!4m5!3m4!1s0x48742078d93d3db7:0x2ae19f7fcefa7994!8m2!3d51.4542645!4d-0.9781303",
    },
  },

  {
    name: "Octopus Energy",
    // position: {
    //   x: 3577,
    //   y: 3820,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1792,
      y: 1907,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.ethicalreading.org.uk/",
    content: `
      <div class="intro">
      <p>Transport accounts for a third of the UK’s carbon emissions. That’s why NatWest and green energy provider Octopus Energy are working together to help people and businesses transition to greener transport.</p>
      <p>Through our partnership with the green tech energy company, NatWest customers can purchase EV charge points at discounted rates. This bundled solution offers the entire stack of infrastructure, technology and software needed to switch to electric vehicles, and also includes a comprehensive assessment of each customer’s needs. </p>
      <p>As well as discounted EV charging points, customers get free access to Octopus Energy’s ‘Electric Juice Network’ - the UK’s only EV roaming service which allows customers to access public charge points across the country, with all costs appearing on their energy bill.</p>
      <p>It's never been easier to switch to greener transport.</p>

      </div>
    `,
    icon: "electric-hybridVehicles.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    carousel: [
      {
        type: "image",
        content: "londonSE/octopusEnergy.jpg",
      },
    ],
    link: {
      text: "London",
      url: "https://www.google.com/maps/place/London/@51.528308,-0.3817765,10z/data=!3m1!4b1!4m5!3m4!1s0x47d8a00baf21de75:0x52963a5addd52a99!8m2!3d51.5073509!4d-0.1277583",
    },
  },

  {
    name: "Tower Transit",
    // position: {
    //   x: 3560,
    //   y: 3692,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1786,
      y: 1838,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.towertransit.co.uk/",
    content: `
      <div class="intro">
      <p>Tower Transit is a bus operator based in Greater London. </p>
      <p>Supporting the Capital’s plans to reduce air pollution, the operator is committed to more sustainable methods of travel including fully electric buses as well as electric-diesel hybrid vehicles. </p>
      <p>Recent funding received from Lombard Asset Finance has enabled the business to drive forward its green agenda and invest in 37 new electric buses to support key routes around London. With over 320 in its fleet, Tower Transit is continually looking to electrify its vehicles to comply with the City of London’s 2030 clean emissions targets – and obtain an ISO 14001 certification.</p>

      </div>
    `,
    icon: "electric-bus.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    carousel: [
      {
        type: "image",
        content: "londonSE/towerTransit.jpg",
      },
    ],
    link: {
      text: "London",
      url: "https://www.google.com/maps/place/London/@51.528308,-0.3817765,10z/data=!3m1!4b1!4m5!3m4!1s0x47d8a00baf21de75:0x52963a5addd52a99!8m2!3d51.5073509!4d-0.1277583",
    },
  },

  {
    ytvideo: `<iframe width="100%" height="394" src="https://www.youtube.com/embed/0cItCqb3-P0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border: solid white 10px; border-radius: 5px;"></iframe>`,
    name: "Circla",
    // position: {
    //   x: 3774,
    //   y: 3938,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1884,
      y: 1968,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.circla.co.uk/",
    content: `
    <div class="intro">
      </div>
    `,
    icon: "eco-beauty.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    link: {
      text: "London",
      url: "https://www.google.com/maps/place/Hurlingham+Studios/@51.5920264,-2.1159491,7.82z/data=!4m5!3m4!1s0x48760f7486e99f6d:0xe45b1ebefc6fdee3!8m2!3d51.4678957!4d-0.2085219",
    },
  },

  {
    ytvideo: `<iframe width="100%" height="394" src="https://www.youtube.com/embed/NG2PQa8bW1I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border: solid white 10px; border-radius: 5px;"></iframe>`,
    name: "Harvest London",
    // position: {
    //   x: 3746,
    //   y: 3773,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1874,
      y: 1883,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.harvest.london/",
    content: `
    <div class="intro">
    </div>
    `,
    icon: "eco-agriculture.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    link: {
      text: "London",
      url: "https://www.google.com/maps/place/Harvest+London/@51.5684459,-0.0379928,17z/data=!3m1!4b1!4m5!3m4!1s0x48761dbbdc070265:0xa5ad2b1cdfd47bfb!8m2!3d51.5684423!4d-0.0358165",
    },
  },
  // /**************************************/
  // /**************************************/
  // /**** Midlands and East of England ****/
  // /**************************************/
  // /**************************************/

  {
    name: "Scrubbee",
    // position: {
    //   x: 2970,
    //   y: 3460,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1485,
      y: 1730,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.scrubbee.co.uk/",
    content: `
    <div class="intro">
    <p>Sustainable-skincare brand Scrubbee is creating affordable face and body scrubs by recycling coffee grounds from local coffee shops.</p>
    <p>The Birmingham-based business was created by Maddie Booth who struggled to find natural skincare products on a student budget. When she couldn’t find what she was looking for, she developed her own facial scrub recipe using only natural ingredients including coffee grounds.</p>
    <p>Scrubbee is looking to launch a range of body scrubs later this year.</p>
    <p>To support the development of her business, Maddie joined the NatWest Accelerator after learning about it through the University of Warwick.</p>

    </div>
    `,
    icon: "eco-beauty.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "midlandsEastEngland/scrubbee.jpg",
      },
    ],
    link: {
      text: "Birmingham",
      url: "https://www.google.com/maps/place/Birmingham/@52.4775214,-1.9338425,12z/data=!3m1!4b1!4m5!3m4!1s0x4870942d1b417173:0xca81fef0aeee7998!8m2!3d52.486243!4d-1.890401",
    },
  },

  {
    name: "Where Does It Come From?",
    // position: {
    //   x: 4015,
    //   y: 3550,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 2005,
      y: 1775,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.wheredoesitcomefrom.co.uk/",
    content: `
    <div class="intro">
    <p>Where Does It Come From? is a social enterprise focusing on positive impacts for people and the planet, and has become a specialist in creating ethical and sustainable supply chains. The Ipswich-based firm was founded in 2013. </p>
    <p>Transparency is central to everything they do and they have developed a QR code system so clients can see exactly how clothing items were created. This includes everything from the source of materials used in production to the communities involved in the creation of the clothing.</p>
    <p>The business has been part of the NatWest Accelerator programme in Milton Keynes.</p>

    </div>
    `,
    icon: "eco-fashion.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "midlandsEastEngland/whereDoesItComeFrom.jpg",
      },
    ],
    link: {
      text: "Ipswich",
      url: "https://www.google.com/maps/place/Where+Does+It+Come+From/@52.0646524,1.1466241,17.99z/data=!4m5!3m4!1s0x47d9a1ce695ac851:0x3225e7646e56093b!8m2!3d52.0646896!4d1.1478691",
    },
  },

  {
    name: "See Limited",
    // position: {
    //   x: 3386,
    //   y: 3575,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1693,
      y: 1788,
    },
    transform: {
      width: 100,
      height: 100,
    },
    // website: "https://www.wheredoesitcomefrom.co.uk/",
    content: `
    <div class="intro">
    <p>See Limited, a UK-wide distribution and manufacturing group headquartered in Northamptonshire, is progressing at speed with its climate change aspirations to become Net Zero by 2030.</p>
    <p>The group is made up of a trio of businesses: Inspired Surfaces Limited, Rex Bousfield Limited and Performance Panels Limited; all of which are involved in the distribution and production of wood veneer and laminate surfaces.</p>
    <p>In 2016, a new management team revitalised the Group and began driving a green agenda, aimed at improving its carbon footprint and encouraging sustainable practices. The group then secured a £1.1 million funding package, to help advance their ambitions.</p>
    <p>As part of its sustainability plans, the business has invested in new machinery, equipment, and processes. </p>
    <p>Since 2019 the group has recorded and measured its C02 impact through its business operations to ensure full transparency with all stakeholders. It has also joined the Science Based Targets initiative, a global partnership aimed at encouraging companies to act against climate change.</p>

    </div>
    `,
    icon: "eco-business2.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "midlandsEastEngland/seeLimited.jpg",
      },
    ],
    link: {
      text: "Northamptonshire",
      url: "https://www.google.com/maps/place/Northamptonshire/@52.3091152,-1.3973797,9z/data=!3m1!4b1!4m5!3m4!1s0x47d8077dccaa4605:0xfe28f15aad79bcf6!8m2!3d52.2729944!4d-0.8755515",
    },
  },

  {
    name: "Eco-Gym",
    // position: {
    //   x: 3168,
    //   y: 3444,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1584,
      y: 1722,
    },
    transform: {
      width: 100,
      height: 100,
    },
    // website: "https://www.wheredoesitcomefrom.co.uk/",
    content: `
    <div class="intro">
    <p>In Birmingham there are a group of entrepreneurial students who have developed an idea that real is fit for business. They are launching the city’s first ‘green’ gym.</p>

    <p>Morgan Roberts, Idrees Sulaiman, Jack Whitehead, Harriet Collinge and Vishal Singh secured a place on NatWest’s Climate Accelerator programme with their Eco-Gym concept. </p>

    <p>The foursome took part in a two-day Hackathon event hosted by NatWest Enterprise and all five of Birmingham’s Universities. They will now take forward their Eco-Gym business, from idea, to concept, to modelling, crowdfunding and eventually launch with the support of NatWest. </p>

    <p>Eco-Gym is positioned to become Birmingham’s first self-sufficient gym, with all energy generated through the work that gym-goers put in on its state-of-the-art machinery. </p>

    <p>Which means it will be the only gym where the instructors are not focused on the figures on the scales or total number of reps, but the amount of watts you are producing. Eco-Gym will also use its space to promote green scaping, eco-therapy, as well as ultimately committing to net zero. </p>

    </div>
    `,
    icon: "gym.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "midlandsEastEngland/ecoGym.jpg",
      },
    ],
    link: {
      text: "Birmingham",
      url: "https://www.google.com/maps?q=birmingham&uact=5&gs_lcp=Cgdnd3Mtd2l6EAMyCgguELEDEEMQkwIyBAgAEEMyBAgAEEMyCggAELEDEIMBEEMyBwgAELEDEEMyBAguEEMyBwguELEDEEMyBQgAEJECMg0ILhCxAxDHARCvARBDMgoIABCxAxCDARBDSgQIQRgAUJ4-WLxMYOBOaABwAngAgAF6iAGwApIBAzEuMpgBAKABAcABAQ&um=1&ie=UTF-8&sa=X&ved=2ahUKEwiJiYah_cHzAhWIDMAKHRZbDaEQ_AUoAXoECAEQAw",
    },
  },

  {
    name: "David Nieper",
    // position: {
    //   x: 3235,
    //   y: 3224,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 1618,
      y: 1612,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://www.davidnieper.co.uk/",
    content: `
    <div class="intro">
    <p>Derbyshire based, David Nieper has made sustainable fashion for 60 years. Through the company’s charitable foundation, they have launched an eco-label designed to be used nationwide by the UK fashion industry. </p>

    <p>Fashion is one of the world’s most polluting industries, accounting for 8+% of global carbon emissions every year. Yet, there is no clear plan of action from government to address fashion’s spiralling climate crisis.</p>
    
    <p>The ‘Carbon Checker’ is a simple label, designed to help consumers choose eco-friendly garments and do their bit to help fight climate change.</p>
    

    </div>
    `,
    icon: "eco-fashion.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "midlandsEastEngland/davidNieper.jpg",
      },
    ],
    link: {
      text: "Alfreton",
      url: "https://www.google.com/maps/search/david+nieper/@53.094406,-1.3852803,17z",
    },
  },

  // /**************************************/
  // /**************************************/
  // /********* North of England ***********/
  // /**************************************/
  // /**************************************/

  {
    name: "Broadacres Housing Association",
    // position: {
    //   x: 3253,
    //   y: 2592,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 1627,
      y: 1296,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://www.broadacres.org.uk/",
    content: `
    <div class="intro">
    <p>Broadacres Housing Association is a not-for-profit housing association based in North Yorkshire that has recently pledged to become net-zero by 2050.</p>
    <p>Established in 1993, the business employs 380 staff and owns 6,500 homes with plans to build a further 800 homes over the next five years. It provides a range of services from repairs to financial guidance for residents. </p>
    <p>As part of its sustainability drive, Broadacres is reducing its waste, switching to an electric or hybrid fleet and helping to tackle fuel poverty by replacing old storage heaters with energy-efficient heating systems such as air-source heat pump technology.</p>

    </div>
    `,
    icon: "energyHomes.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "northEngland/broadacres.jpg",
      },
    ],
    link: {
      text: "Northallerton",
      url: "https://www.google.com/maps/place/Broadacres/@54.4130519,-1.6130577,11.25z/data=!4m9!1m2!2m1!1sBroadacres+Housing+Association!3m5!1s0x0:0x75807156da290c1d!8m2!3d54.3473728!4d-1.4441696!15sCh5Ccm9hZGFjcmVzIEhvdXNpbmcgQXNzb2NpYXRpb26SARNob3VzaW5nX2Fzc29jaWF0aW9u",
    },
  },

  {
    name: "KIHT Collective",
    // position: {
    //   x: 2993,
    //   y: 2821,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 1497,
      y: 1411,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://kiht.co.uk/",
    content: `
    <div class="intro">
    <p>After working in the fashion industry for ten years, designer and entrepreneur Danielle King launched KIHT Collective in July 2020 with a mission to build a new model for slow fashion. </p>
    <p>The Manchester-based sustainable sportswear brand stocks a range of gymwear made from ethical materials that aim to empower women to lead active lifestyles while knowing they are making a positive difference to the planet. </p>
    <p>Committed to minimising its environmental impact, KIHT Collective’s latest line ‘Essentials’ is made from recycled polyester, organic cotton and is printed using sustainable ink. The business has also teamed up with charity TreeSisters to plant a tree for every purchase. </p>

    </div>
    `,
    icon: "eco-fashion.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "northEngland/kiht.jpg",
      },
    ],
    link: {
      text: "Manchester",
      url: "https://www.google.com/maps/place/Manchester/@53.472225,-2.2935023,12z/data=!3m1!4b1!4m5!3m4!1s0x487a4d4c5226f5db:0xd9be143804fe6baa!8m2!3d53.4807593!4d-2.2426305",
    },
  },

  {
    name: "S2S Electronics",
    // position: {
    //   x: 3396,
    //   y: 3046,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1698,
      y: 1523,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://s2s.uk.com/",
    content: `
    <div class="intro"> 
    <p>Based in Rotherham, S2S Electronics specialises in the disposal and management of IT assets at the end of their lifecycle, offering a certified and eco-friendly ITAD service. </p>
    <p>With e-waste becoming the world’s fastest-growing waste stream, S2S Electronics’ mission is to help businesses securely and safely discard old tech, encouraging the move to a more sustainable future.  </p>
    <p>As well as helping its customers to recycle their assets responsibly, S2S Electronics’ commitment to a sustainable future has been reinforced by its pledge to become net-zero by 2050. </p>

    </div>
    `,
    icon: "tech-ecoRecycling.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "northEngland/s2sElectronics.jpg",
      },
    ],
    link: {
      text: "Rotherham",
      url: "https://www.google.com/maps?q=S2S+Electronics+Ltd+Unit+3,+Farfield+Park+Manvers+Way,+Rotherham+South+Yorkshire+United+Kingdom+S63+5DB&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjeo96Pv-ryAhWZEMAKHQaOBA8Q_AUoAXoECAEQAw",
    },
  },

  {
    name: "C&O Coatings",
    // position: {
    //   x: 2771,
    //   y: 2837,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1386,
      y: 1419,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.candocoatings.co.uk/",
    content: `
    <div class="intro"> 
    <p>A specialist architectural firm based in St Helens successfully secured £300,000 of funding in 2021 to create a pioneering new environmentally friendly powder coating line. </p>
    <p>The new line enabled the business to reduce costs and improve efficiency, while helping to minimise waste and create a more economical, sustainable operation.  </p>
    <p>The investment in this equipment helped to futureproof the business and boost sales levels, which will in turn create more jobs for the local community. </p>
    <p>The total cost of the project, including commissioning and installation of the new line, was approximately £600,000, with the bank financing half of this amount to facilitate the investment.</p>

    </div>
    `,
    icon: "factory.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "northEngland/coCoatings.jpg",
      },
    ],
    link: {
      text: "St Helens",
      url: "https://www.google.com/maps/place/C+%26+O+Powder+Coatings+Ltd/@53.4301269,-2.7045512,17z/data=!3m1!4b1!4m5!3m4!1s0x487b1cb94acc0d0d:0x8fa218997b854f09!8m2!3d53.4300538!4d-2.7025223",
    },
  },

  {
    ytvideo: `<iframe width="100%" height="394" src="https://www.youtube.com/embed/JDLmlReuk5E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border: solid white 10px; border-radius: 5px;"></iframe>`,
    name: "GTEC training",
    // position: {
    //   x: 3042,
    //   y: 2572,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 1521,
      y: 1286,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://gtec.co.uk/",
    content: `
    <div class="intro">
    </div>
    `,
    icon: "eco-business.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    link: {
      text: "North Yorkshire",
      url: "https://www.google.com/maps/place/GTEC+Training+Ltd/@54.3050979,-2.1984773,17z/data=!4m9!1m2!2m1!1sGTEC+Group+GTEC+House+Charcoals+Way+Upper+Wensleydale+Business+Park+Hawes,+North+Yorkshire,+DL8+3AU!3m5!1s0x487c6c6a6e045a6f:0xa20084161002b8bc!8m2!3d54.3058477!4d-2.1943462!15sCmNHVEVDIEdyb3VwIEdURUMgSG91c2UgQ2hhcmNvYWxzIFdheSBVcHBlciBXZW5zbGV5ZGFsZSBCdXNpbmVzcyBQYXJrIEhhd2VzLCBOb3J0aCBZb3Jrc2hpcmUsIERMOCAzQVWSAQ90cmFpbmluZ19jZW50ZXI",
    },
  },


  {
    name: "The North East England Climate Coalition",
    // position: {
    //   x: 3126,
    //   y: 2265,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 1563,
      y: 1133,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://neecco.org.uk/",
    content: `
    <div class="intro"> 
    <p>The North East England Climate Coalition’s (NEECCo) goal is simple – to make the region the greenest in the UK.</p>

    <p>NEECCo is a regional initiative bringing together the private, public, and voluntary and community sector under the joint ambition of ‘Becoming England’s Greenest region’, which aims to address the climate crisis, reverse biodiversity loss and ensure a just transition to a green economy.</p>

    <p>It’s mission is to enable the people of the North East to confront the climate emergency using the hard lessons drawn from the past, the strengths of community life in the present, and the hard science that says actions are needed now to tackle the climate emergency, reverse ecological collapse and deliver an urgent and just transition to a positive future.</p>

    <p>NatWest has recently become NEECCo’s first corporate partner, and will be working with the organisation on a variety of events for businesses as well as the wider community to support NEECCo’s work in the run-up to COP26 and beyond.</p>
    </div>
    `,
    icon: "eco-growth.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "northEngland/neecco.jpg",
      },
    ],
    link: {
      text: "Newcastle",
      url: "https://www.google.com/maps/place/VONNE+(Voluntary+Organisations'+Network+North+East)/@54.9761477,-1.6102117,17z/data=!3m1!4b1!4m5!3m4!1s0x487e70b7094fe843:0xa8d7135e4c74d5f0!8m2!3d54.9761477!4d-1.608023",
    },
  },

  // /**************************************/
  // /**************************************/
  // /************* Scotland **************/
  // /**************************************/
  // /**************************************/

  {
    name: "SolarisKit",
    // position: {
    //   x: 2811,
    //   y: 1394,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 1406,
      y: 697,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://solariskit.com/",
    content: `
    <div class="intro">
    <p>Established in 2019, SolarisKit is the world’s first flat-packable thermal solar water heater. </p>
    <p>Designed by mechanical engineer, Dr Faisal Ghani to harness solar energy affordably, its prismatic design means it converts sunlight into hot water up to 50 degrees Celsius, ideal for households, businesses and industries such as textiles, breweries and food. </p>
    <p>Following advice from Royal Bank of Scotland Climate Entrepreneur Accelerator, SolarisKit launched exclusively on the Amazon Launchpad Scheme in 2021, making it available to a global audience. </p>
    <p>The business has pledged to plant 25 trees globally for every kit sold and estimated it could offset roughly 40,000 kilogrammes of carbon per year. </p>

    </div>
    `,
    icon: "solarTechnology.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "scotland/solarisKit.jpg",
      },
    ],
    link: {
      text: "Dundee",
      url: "https://www.google.com/maps/place/Michelin+Scotland+Innovation+Parc/@56.4844082,-2.8968793,15.13z/data=!4m5!3m4!1s0x48865da3f86c39eb:0x8432409c1c89d083!8m2!3d56.485133!4d-2.8932822",
    },
  },


  {
    name: "Leask Marine",
    // position: {
    //   x: 2830,
    //   y: 52,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 1415,
      y: 26,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://www.leaskmarine.com/",
    content: `
    <div class="intro">
    <p>Leask Marine was founded in 1985, providing marine construction and diving services around Orkney and the Northern Isles. </p>
    <p>Since then, it has become a world-leader in the tidal and off-shore energy market, supporting the mobilisation, deployment, installation, commissioning, maintenance, inspection and de-commissioning of the world’s foremost marine energy developers' devices.</p>
    <p>The Orkney based business is an expert in the advancement and maintenance of renewable marine technologies, which are increasingly at the helm of the climate agenda as the search for ways to preserve the world’s diminishing resources continues.</p>
    <p>As global focus turns towards a greener future, Leask Marine continues to expand its services to support the growing renewable sector and help provide a more sustainable future for all. </p>

    </div>
    `,
    icon: "tidal-wavePower.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "scotland/leaskMarine.jpg",
      },
    ],
    link: {
      text: "Kirkwall, Orkney",
      url: "https://www.google.com/maps/place/Leask+Marine+Ltd/@58.9920387,-2.9737217,17z/data=!3m1!4b1!4m5!3m4!1s0x489b015cb7918c67:0x36d488d30c86526c!8m2!3d58.992063!4d-2.9714802",
    },
  },

  {
    name: "Grampian Growers",
    // position: {
    //   x: 2882,
    //   y: 1200,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 1441,
      y: 600,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://www.grampiangrowers.co.uk/",
    content: `
    <div class="intro">
    <p>Formed in 1968, Grampian Growers is a farming co-operative leading in the production and exportation of crops, including daffodils, bulbs and potatoes, from its base in Montrose. </p>
    <p>The business has committed to improving its sustainability through investment in renewable energy systems on-site and membership of sustainable accreditation schemes recognised by the UK’s biggest retailers such as Red Tractor and Safe Haven. </p>
    <p>New funding from Royal Bank of Scotland in September enabled the business to open a new low carbon HQ incorporating solar panels and natural ventilation, further minimising its environmental impact.</p>
   
    </div>
    `,
    icon: "eco-agriculture.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "scotland/grampianGrowers.jpg",
      },
    ],
    link: {
      text: "Montrose",
      url: "https://www.google.com/maps/place/Grampian+Growers+Ltd/@56.7538223,-2.4895069,17z/data=!3m1!4b1!4m5!3m4!1s0x48869a51af7dea49:0xe7a22eaed24b03b5!8m2!3d56.7538223!4d-2.4873182",
    },
  },

  {
    ytvideo: `<iframe width="100%" height="394" src="https://www.youtube.com/embed/MtOH9kS5ETg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border: solid white 10px; border-radius: 5px;"></iframe>`,
    name: "Green Marine",
    // position: {
    //   x: 2610,
    //   y: 100,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 1305,
      y: 50,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://greenmarineuk.com/",
    content: `
    <div class="intro">
    </div>
    `,
    icon: "tidal-wavePower.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    link: {
      text: "Stromness",
      url: "https://www.google.com/maps/place/Green+Marine+(UK)+Ltd/@58.9668027,-3.2978158,17z/data=!3m1!4b1!4m5!3m4!1s0x489afa06b6a4df59:0x2f0c7f789e4bc9b3!8m2!3d58.9668096!4d-3.2956881",
    },
  },  

  {
    ytvideo: `<iframe width="100%" height="394" src="https://www.youtube.com/embed/DHwdl3AhmaU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border: solid white 10px; border-radius: 5px;"></iframe>`,
    name: "Port of Cromarty Firth",
    // position: {
    //   x: 2449,
    //   y: 637,
    // },
    // transform: {
    //   width: 400,
    //   height: 400,
    // },
    position: {
      x: 1225,
      y: 319,
    },
    transform: {
      width: 200,
      height: 200,
    },
    website: "https://www.pocf.co.uk/",
    content: `
    <div class="intro">
    </div>
    `,
    icon: "port.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    link: {
      text: "Invergordon",
      url: "https://www.google.com/maps/place/Cromarty+Firth+Port+Authority,+Invergordon,+UK/@57.7713805,-5.1217498,9.3z/data=!4m5!3m4!1s0x488f9b47a77a7019:0xbe966d530ced9a86!8m2!3d57.6862776!4d-4.1702625",
    },
  },

  {
    name: "Storas Uibhist",
    // position: {
    //   x: 1476,
    //   y: 1007,
    // },
    // transform: {
    //   width: 300,
    //   height: 300,
    // },
    position: {
      x: 739,
      y: 507,
    },
    transform: {
      width: 150,
      height: 150,
    },
    website: "https://storasuibhist.com/",
    content: `
    <div class="intro">
    <p>Some communities are bringing an energy to where they live – in every sense of the word.</p>
    <p>With input from Royal Bank of Scotland and its NatWest partner Lombard, Storas Uibhist is making some of the Scottish island communities of South Uist stronger than ever before. The business is a community owned company which oversees the operation of a wind turbine, providing enough energy to supply 850 tenant crofters electricity, as well as providing an income for the communities of Eriskay, South Uist and parts of Benbecula.</p>
    <p>The company also has enough energy and support to, oversee the operation of the wind farm, in addition to a number of other projects across the 93,000-acre South Uist Estate, including the management and maintenance of the Bonnie Prince Charlie trail, the Lochboisdale Harbour and the world-renowned Askernish Golf Course.</p>
    </div>
    `,
    icon: "windFarm.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "scotland/storas.jpg",
      },
    ],
    link: {
      text: "South Uist",
      url: "https://www.google.com/maps/place/South+Uist/@56.341503,-11.7315236,6z/data=!4m5!3m4!1s0x488ce9bb54bc392b:0xe2479fea442b2099!8m2!3d57.264214!4d-7.33121",
    },
  },

  {
    name: "Intelidigest",
    // position: {
    //   x: 2908,
    //   y: 1665,
    // },
    // transform: {
    //   width: 300,
    //   height: 300,
    // },
    position: {
      x: 1454,
      y: 833,
    },
    transform: {
      width: 150,
      height: 150,
    },
    website: "https://intellidigest.com/?doing_wp_cron=1634287747.5186190605163574218750",
    content: `
    <div class="intro">
    <p>Bringing higher education, learning and bank support together can be a key driver in helping deliver a sustainable future. Intellidigest is a great example of this.</p>
    <p>Operating from Heriot Watt University in Edinburgh and led by Dt Ifeyinwa Kanu, the five year old company is pioneering the elimination of food waste through the promotion of a circular economy, by preventing edible food going to waste, and by converting inedible food waste into sustainable chemicals. These chemicals can then be used for the likes of food packaging and cosmetics.</p>
    <p>Dr Kanu is a graduate of the Royal Bank of Scotland’s 18-month Accelerator programme which provides support systems for business owners, allowing them time to focus on developing their company through one-to-ones and networking opportunities.</p>
    <p>Through the Accelerator, a ‘Power Panel’ of industry experts worked with IntelliDigest on direction and commercialisation, leading seasoned scientific lawyer Patricia Barclay to take on the role of chairperson with the company.</p>
    <p>Now Dr Kanu is playing a role at COP26 and will be talking about her experience to a host of international delegates with the CBI.</p>

    </div>
    `,
    icon: "foodWaste.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "scotland/intellidigest.jpg",
      },
    ],
    link: {
      text: "Edinburgh",
      url: "https://www.google.com/maps?q=heriot+watt+university&um=1&ie=UTF-8&sa=X&sqi=2&ved=2ahUKEwibzJyf_MvzAhX8kmoFHffdC80Q_AUoAXoECAEQAw",
    },
  },

  {
    name: "SOLTROPY",
    // position: {
    //   x: 2309,
    //   y: 1639,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 1155,
      y: 820,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://www.soltropy.com/",
    content: `
    <div class="intro">
    <p>Operating from Royal Bank of Scotland’s Entrepreneur Accelerator Hub in Glasgow, Soltropy is a business which is literally at the heart of the world’s climate change capital.</p>
    <p>The solar thermal firm, run by Stuart Speake, started life as an idea back in 2012 – solar panels that could benefit from Scotland’s icier climes. To successfully use solar energy in colder countries most panels need to be fitted with anti-freeze to prevent winter weather damaging the system. This restricts the size of panel that can be fitted, with the antifreeze putting the panel at risk of overheating in the summer months if the surface area is too large.</p>
    <p>This can be challenging as solar panels do not actually generate much energy in the winter months. Yet the antifreeze cannot be omitted or the panel will be damaged and unworkable by the summer, when the majority of the energy generating takes place.</p>
    <p>So, Mr Speake designed a system that doesn’t require antifreeze at all. This means his business can install much larger panels that won’t risk overheating in the summer, but also won’t freeze in the winter. Already UK holiday parks are warming to the new designs.</p>
      </div>
    `,
    icon: "solarTechnology.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "scotland/soltropy.jpg",
      },
    ],
    link: {
      text: "Glasgow",
      url: "https://www.google.com/maps/place/Glasgow/@55.8554402,-4.3026694,12z/data=!3m1!4b1!4m5!3m4!1s0x488815562056ceeb:0x71e683b805ef511e!8m2!3d55.864237!4d-4.251806",
    },
  },

  // /**************************************/
  // /**************************************/
  // /************ South West **************/
  // /**************************************/
  // /**************************************/

  {
    name: "Green Mortgages",
    // position: {
    //   x: 2956,
    //   y: 3970,
    // },
    // transform: {
    //   width: 180,
    //   height: 180,
    // },
    position: {
      x: 1478,
      y: 1985,
    },
    transform: {
      width: 90,
      height: 90,
    },
    website: "https://www.bristolpost.co.uk/special-features/save-money-help-planet-green-5342756",
    content: `
    <div class="intro">
    <p>To help reduce climate change, a number of banks and building societies including NatWest are rewarding customers who purchase energy-efficient homes with discounted mortgage rates and cashback rewards.</p>
    <p>Bristol couple Paul Anastasiades and Bailey Thomas bought their very first home in Bristol with a Green Mortgage by NatWest, and are amongst a growing number of home buyers using their mortgage to help the environment.</p>
    <p>They said: "The green credentials of our house mean that we now not only save on our monthly bills, but also on our mortgage repayments. It's a great way to limit your impact on the environment and save money at the same time."</p>
    <p>You can read more about their story by clicking the button below.</p>
    </div>
    `,
    icon: "energyHomes.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "southWest/greenMortgage.jpg",
      },
    ],
    link: {
      text: "Bristol",
      url: "https://www.google.com/maps/place/Bristol/@51.4685749,-2.6609286,12z/data=!3m1!4b1!4m5!3m4!1s0x4871836681b3d861:0x8ee4b22e4b9ad71f!8m2!3d51.454513!4d-2.58791",
    },
  },

  {
    name: "Fawley Waterside",
    // position: {
    //   x: 3167,
    //   y: 4222,
    // },
    // transform: {
    //   width: 180,
    //   height: 180,
    // },
    position: {
      x: 1584,
      y: 2111,
    },
    transform: {
      width: 90,
      height: 90,
    },
    website: "https://fawleywaterside.co.uk/",
    content: `
    <div class="intro">
    <p>The vision of Fawley Waterside is simple: to turn one of the largest brownfield sites in the South of England into the most sustainable town in the country.</p>
    <p>Built upon the old Fawley Power Station site, in the heart of the New Forest, the proposed town would be a thriving residential and commercial waterside community that becomes a destination for employment and leisure activity.</p>
    <p>The team behind it are working so that green mortgages would be available to residents moving into its sustainable homes, many of which would be affordable housing, while the town itself would be a hub for green energy and green tech businesses with the potential for a NatWest Climate Accelerator hub for entrepreneurs.</p>

    </div>
    `,
    icon: "energyHomes.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "southWest/fawleyWaterside.jpg",
      },
    ],
    link: {
      text: "Southampton",
      url: "https://www.google.com/maps/place/Fawley+Power+Station/@50.8249067,-1.3420878,13.67z/data=!4m9!1m2!2m1!1sfawley+power+station!3m5!1s0x0:0xf3a4283e7a9b98fa!8m2!3d50.8179657!4d-1.3277689!15sChRmYXdsZXkgcG93ZXIgc3RhdGlvbloWIhRmYXdsZXkgcG93ZXIgc3RhdGlvbpIBC3Bvd2VyX3BsYW50mgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVVIzZUVreVowOUJFQUU",
    },
  },

  {
    name: "Bristol Green Capital Partnership",
    // position: {
    //   x: 2847,
    //   y: 4048,
    // },
    // transform: {
    //   width: 180,
    //   height: 180,
    // },
    position: {
      x: 1424,
      y: 2024,
    },
    transform: {
      width: 90,
      height: 90,
    },
    website: "https://bristolgreencapital.org/",
    content: `
    <div class="intro">
    <p>Founded in 2007, Bristol Green Capital Partnership is a unique partnership of over 1,000 member organisations, including NatWest, who have committed to working towards Bristol becoming a sustainable city with a high-quality of life for all.</p>
    <p>It is working towards the vision of a Bristol that is a thriving low carbon city powered by sustainable energy, where no one goes cold at home; a circular economy city, where organisations and citizens reject a throw-away society, reducing, re-using and recycling; a city where the food we grow and eat is good for people, places and planet; a nature-rich city, where wildlife abounds and everyone has access to inspiring green spaces; a city where it is enjoyable, convenient and affordable for everyone to travel sustainably every day.</p>
    <p>And it is supporting its member organisations to achieve this goal.</p>
    </div>
    `,
    icon: "eco-business2.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "southWest/greenCapital.jpg",
      },
    ],
    link: {
      text: "Bristol",
      url: "https://www.google.com/maps/place/Bristol/@51.4685749,-2.6609286,12z/data=!3m1!4b1!4m5!3m4!1s0x4871836681b3d861:0x8ee4b22e4b9ad71f!8m2!3d51.454513!4d-2.58791",
    },
  },

  {
    name: "Lettus Grow",
    // position: {
    //   x: 3067,
    //   y: 4073,
    // },
    // transform: {
    //   width: 180,
    //   height: 180,
    // },
    position: {
      x: 1534,
      y: 2037,
    },
    transform: {
      width: 90,
      height: 90,
    },
    website: "https://www.lettusgrow.com/",
    content: `
    <div class="intro">
    <p>LettUs Grow is a business doing incredible things in the sustainable farming sector.</p>
    <p>The business, based in Bristol, is an indoor farming technology provider that designs and builds aeroponic technology and control software.</p>
    <p>So what does that actually mean? Well, they build vertical farms!</p>
    <p>Founded in Bristol in 2015 by three graduates from the University of Bristol who developed new technology to create vertical farms that bring resilience to the food supply chain and support sustainable farming.</p>
    <p>As a result the company is building the farms of the future and in doing so reducing the waste and carbon footprint of fresh produce.</p>

    </div>
    `,
    icon: "eco-agriculture.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "southWest/lettusGrow.jpg",
      },
    ],
    link: {
      text: "Bristol",
      url: "https://www.google.com/maps/place/LettUs+Grow/@51.4524841,-2.547767,17.29z/data=!4m9!1m2!2m1!1sUnit+4,+Avon+Valley+Business+Park+Chapel+Way+Bristol,+BS4+4EU!3m5!1s0x0:0x46dc1b6ec67e1c1a!8m2!3d51.4531462!4d-2.5470815!15sCj1Vbml0IDQsIEF2b24gVmFsbGV5IEJ1c2luZXNzIFBhcmsgQ2hhcGVsIFdheSBCcmlzdG9sLCBCUzQgNEVVkgEXZmFybV9lcXVpcG1lbnRfc3VwcGxpZXI",
    },
  },

  {
    name: "Harts of Stur",
    // position: {
    //   x: 2967,
    //   y: 4212,
    // },
    // transform: {
    //   width: 180,
    //   height: 180,
    // },
    position: {
      x: 1484,
      y: 2106,
    },
    transform: {
      width: 90,
      height: 90,
    },
    website: "https://www.hartsofstur.com/",
    content: `
    <div class="intro">
    <p>This four-generation family business in North Dorset is committed to ensuring the area they live in and work can be enjoyed by future generations. The cookware, kitchenware and electrical specialist was established in 1919, but more recently has been taking some big steps to protect the environment it calls its home. </p>
    <p>It already recycles more than 300 tonnes of wooden pallets, cardboard, plastic, metal and electrical items each year and with a large percentage of the business being mail order was keen to reduce the amount of non-recyclable packaging used on thousands of orders every week.</p>
    <p>So it sourced a biodegradable alternative to polystyrene chips used for packaging, which can be put on a compost patch or added to household food recycling. And as part of its redevelopment, it fitted replacement LED lighting, an air source heat pump system, and solar panels to reduce its carbon footprint further. Inspirational stuff.</p>

    </div>
    `,
    icon: "eco-retail.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "southWest/hartStur.jpg",
      },
    ],
    link: {
      text: "Dorset",
      url: "https://www.google.com/maps/place/Harts+of+Stur/@50.9273996,-2.3040356,18.71z/data=!3m1!5s0x48724b0b3b0a83d7:0x74873a0f264c2f48!4m13!1m7!3m6!1s0x48724b0ad5dbe589:0xba82a2d5b4c3a81c!2sStation+Rd,+Sturminster+Newton!3b1!8m2!3d50.9275679!4d-2.3031022!3m4!1s0x48724b0b3b030037:0xce0f37ed2f7e9c00!8m2!3d50.9279931!4d-2.3018792",
    },
  },

  {
    name: "SWEEP",
    // position: {
    //   x: 2626,
    //   y: 4190,
    // },
    // transform: {
    //   width: 250,
    //   height: 210,
    // },
    position: {
      x: 1313,
      y: 2095,
    },
    transform: {
      width: 125,
      height: 105,
    },
    website: "https://sweep.ac.uk/",
    content: `
    <div class="intro">
    <p>The South West Partnership for Environment and Economic Prosperity (SWEEP) is an initiative helping deliver economic and community benefits to the South West, whilst also protecting and enhancing the area's natural resources. The organisation brings academic experts, businesses and policy makers together to solve some of the challenges involved in managing, utilising and improving the natural environment.</p>
    <p>A collaboration of three research institutions: the University of Exeter, the University of Plymouth and Plymouth Marine Laboratory, it is working together with a large group of businesses, policy and community partners including NatWest to drive positive change across the region.</p>

    </div>
    `,
    icon: "eco-growth.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "southWest/sweep.jpg",
      },
    ],
    link: {
      text: "Exeter",
      url: "https://www.google.com/maps/place/Exeter/@50.7244072,-3.5835871,12z/data=!3m1!4b1!4m5!3m4!1s0x486c52c4d4498da7:0xa976e3256bdfad1e!8m2!3d50.718412!4d-3.533899",
    },
  },

  {
    name: "Goodwood",
    // position: {
    //   x: 3474,
    //   y: 4123,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1737,
      y: 2062,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.goodwood.com/estate/our-environment/sustainability/",
    content: `
    <div class="intro">
    <p>Goodwood’s famous 1,200-acre estate is probably best known for its Festival of Speed, yet the charming Sussex manor has a long history of preservation and conservation.</p>
    <p>From introducing a hive home to 60,000 bees to pollinate wildflowers to a tree replanting programme that sees it plant five trees for every old, dead or dangerous tree it has to remove, the estate has developed its own sustainability strategy targeting climate change.</p>
    <p>This strategy covers all aspects of the estate and its activities, from farming with a self-sustaining and certified organic system and responsible forestry focused on continuous cover via natural regeneration, to its events. And although these can provide a more substantial challenge in terms of balancing environmental impact with the benefits to the local community, charities and a global audience it is committed to becoming a carbon positive estate.</p>
    </div>
    `,
    icon: "eco-growth.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "southWest/goodwood.jpg",
      },
    ],
    link: {
      text: "Chichester",
      url: "https://www.google.com/maps/place/Goodwood+House/@50.8686015,-0.754465,15z/data=!4m9!1m2!2m1!1sgoodwood+estate!3m5!1s0x48744d32d45dde09:0xa33361d7e006c60f!8m2!3d50.872389!4d-0.738866!15sCg9nb29kd29vZCBlc3RhdGVaESIPZ29vZHdvb2QgZXN0YXRlkgENY291bnRyeV9ob3VzZZoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VSWmRHRmZTa3AzRUFF",
    },
  },

  {
    name: "Marwell Wildlife",
    // position: {
    //   x: 3315,
    //   y: 4168,
    // },
    // transform: {
    //   width: 180,
    //   height: 180,
    // },
    position: {
      x: 1658,
      y: 2084,
    },
    transform: {
      width: 90,
      height: 90,
    },
    website: "https://www.marwell.org.uk/",
    content: `
    <div class="intro">
    <p>This world-renowned wildlife conservation charity has been working hard to reduce its carbon footprint – or should that be hoof or pawprint? </p>
    <p>In fact in the last decade, it has reduced that footprint by 77%.</p>
    <p>Dr. Tim Woodfine, is Director of Conservation at Marwell Wildlife, which is dedicated to the global conservation of biodiversity and other natural resources.</p>
    <p>The charity engages with over 40,000 children and young people through its conservation educational programmes and welcomes over 500,000 visitors each year to its 140 acre zoological park in Hampshire.</p>
    <p>He said: “Part of our mission is to look after the wider environment because without that neither wildlife or humans do well. People know a lot about the zoo of course, but perhaps much less about our work internationally where our focus really is on restoring eco-systems, which includes some of the threatened species that live there as well. </p>
    <p>“We first looked at our sustainability and carbon footprint back in 2008 and we have been measuring it and making incremental improvements ever since. We think by the end of 2022 we should be carbon neutral or indeed even better, sequestering more carbon than we are producing operationally.”</p>

    </div>
    `,
    icon: "zoo.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "southWest/marwellWildlife.jpg",
      },
    ],
    link: {
      text: "Winchester",
      url: "https://www.google.com/maps?q=marwell+wildlife&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjKscXQrJ_zAhWNilwKHZeKA2wQ_AUoAXoECAEQAw",
    },
  },

  // /**************************************/
  // /**************************************/
  // /************** Wales ****************/
  // /**************************************/
  // /**************************************/


  {
    name: "Cynnal Cymru",
    // position: {
    //   x: 2853,
    //   y: 3841,
    // },
    // transform: {
    //   width: 180,
    //   height: 180,
    // },
    position: {
      x: 1427,
      y: 1921,
    },
    transform: {
      width: 90,
      height: 90,
    },
    website: "https://cynnalcymru.com/",
    content: `
    <div class="intro">
    <p>Cynnal Cymru-Sustain Wales is a national charity dedicated to supporting organisations and businesses in Wales to deliver on sustainable development. Working across multiple sectors the charity’s mission is to make Wales and the UK a sustainable nation by enabling action towards a fair and just society, a low carbon economy and a thriving natural environment.</p>
    <p>With its charitable aims at its core, it delivers wide-reaching work touching on many agendas including climate change and decarbonisation, sustainable travel, renewable energy, affordable housing, the Foundational Economy, in-work poverty, management of natural resources and much more.</p>

    
    </div>
    `,
    icon: "eco-business.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "wales/cynnalCymru.jpg",
      },
    ],
    link: {
      text: "Cardiff",
      url: "https://www.google.com/maps/place/Cynnal+Cymru+-+Sustain+Wales/@51.4648924,-3.1704804,17z/data=!3m1!4b1!4m5!3m4!1s0x486e03361892102f:0xc12dddc738d31623!8m2!3d51.4648924!4d-3.1682917",
    },
  },

  {
    name: "Moneypenny",
    // position: {
    //   x: 2807,
    //   y: 3090,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1404,
      y: 1545,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.moneypenny.com/uk/",
    content: `
    <div class="intro">
    <p>Moneypenny is a business that really has put the climate at the heart of its ethical values. The business, which is based in Wrexham and launched in 2000, is the world’s leading provider of telephone answering, outsourced switchboard, and live chat services and has gone to great lengths to ensure its own climate footprint is making a positive difference to the environment. </p>
    <p>Its head office was designed to incorporate as many green initiatives as possible including ground source heating, sensor activated LED lighting, intranet facility to coordinate car sharing, extensive green belt and wetland outside spaces purposefully retained and maintained, rainwater capture, and recycling all its refuse. Top job Team Moneypenny!</p>
   
    </div>
    `,
    icon: "eco-business.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "wales/moneypenny.jpg",
      },
      ],
    link: {
      text: "Wrexham",
      url: "https://www.google.com/maps/place/Moneypenny/@53.0466125,-3.0214937,17z/data=!3m1!4b1!4m5!3m4!1s0x487ac70015636a11:0x7b0846d88b09ac7a!8m2!3d53.0466125!4d-3.019305",
    },
  },

  {
    name: "North Wales Mersey Dee Business Council",
    // position: {
    //   x: 2650,
    //   y: 3364,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1325,
      y: 1682,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://nwmdbusinesscouncil.org/",
    content: `
    <div class="intro">
    <p>The North Wales Mersey Dee Business Council is a regional umbrella body for the private sector in North Wales and the Mersey Dee area. It works passionately to support businesses in the region and the local economies. One of the key drivers of this activity is helping businesses reduce their carbon footprint and benefit from the transition to a more sustainable economy.</p>
    <p>Earlier this year it held its Net Zero 2021 online conference, an event designed to encourage private sector and not-for-profit organisations across the regions to reduce their carbon footprint. It showcased how enterprises of any size can reduce their carbon footprint and improve their performance at the same time.</p>
    </div>
    `,
    icon: "eco-business2.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "wales/northWalesCouncil.jpg",
      },
    ],
    link: {
      text: "North Wales",
      url: "https://www.google.com/maps/place/North+Wales/@52.9847201,-4.3260336,9z/data=!3m1!4b1!4m5!3m4!1s0x486541b670202709:0x87f67d40aaba0eb1!8m2!3d53.0711149!4d-3.8080783",
    },
  },

  {
    name: "Aberystwyth University",
    // position: {
    //   x: 2483,
    //   y: 3534,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1242,
      y: 1767,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.aber.ac.uk/en/",
    content: `
    <div class="intro">
    <p>Many universities across the UK are leading the fight against climate change and Aberystwyth University is one of the shining examples.</p>
    <p>In 2019 the university joined other organisations in declaring a climate emergency, announcing steps to reduce its investments in fossil fuels. It voluntarily agreed to commit to a net zero target by the end of the financial year 2030-31. </p>
    <p>Over the last 12 months, it has invested £3.4m on energy efficiency projects, reducing its CO2 emissions by over 900 tonnes per year as it looks to reach that landmark goal. And in a significant further step, the university is soon to begin the installation of state-of-the-art solar panels.</p>
    <p>It has also led pioneering academic research about climate change and runs several degrees on the subject matter that are contributing to the creation of new ideas benefiting society and making a difference to everyday lives.</p>

    </div>
    `,
    icon: "solarTechnology.svg",
    // brand: "natwest-white.svg",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "wales/aberystwythUniversity.jpg",
      },
    ],
    link: {
      text: "Aberystwyth",
      url: "https://www.google.com/maps/place/Prifysgol+Aberystwyth+University,+Penglais+Campus/@52.4227388,-4.0773764,13z/data=!4m9!1m2!2m1!1saberystwyth+university!3m5!1s0x486f764d0ef055ff:0x1efcaaf9545d5b54!8m2!3d52.4182991!4d-4.0638779!15sChZhYmVyeXN0d3l0aCB1bml2ZXJzaXR5kgEKdW5pdmVyc2l0eQ",
    },
  },

  {
    name: "Adventure Tours",
    // position: {
    //   x: 2486,
    //   y: 3150,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1243,
      y: 1575,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://adventuretoursuk.com/",
    content: `
    <div class="intro">
    <p>Adventure Tours UK are a responsible tour operator with a passion for making people’s adventure trips support local businesses and the environment too.</p>
    <p>They are a signatory of Tourism Declares a Climate Emergency, a global community committed to helping tourism find a balance with the climate, restore biodiversity, and support vulnerable people across the world.</p>
    <p>Closer to home the company, based in Denbighshire, is supporting the creation of a new a woodland by planting a tree for every traveller on its small group adventure tours and activity holidays. </p>
    <p>Company co-founder Claire Copeman said: “Like most outdoor adventurers, we want to protect the wild spaces we escape to, which is why responsible, sustainable travel is so important to us.”</p>

    </div>
    `,
    icon: "eco-tourism.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "wales/adventureTours.jpg",
      },
    ],
    link: {
      text: "Denbighshire",
      url: "https://www.google.com/maps/place/Denbighshire/@53.1093166,-3.6263055,10z/data=!3m1!4b1!4m5!3m4!1s0x48651428c4062295:0x89045550db0ccc31!8m2!3d53.1842288!4d-3.4224985",
    },
  },

  {
    name: "Techniquest",
    // position: {
    //   x: 2727,
    //   y: 3840,
    // },
    // transform: {
    //   width: 180,
    //   height: 180,
    // },
    position: {
      x: 1364,
      y: 1920,
    },
    transform: {
      width: 90,
      height: 90,
    },
    website: "https://techniquest.org/",
    content: `
    <div class="intro">
    <p>Techniquest is the UK’s longest-established Science Discovery Centre, set on the waterfront of Cardiff Bay. </p>

    <p>Housing over 100 interactive exhibits that can be enjoyed by adults and children alike, the zones of the Science Capital section of the building include one completely dedicated to the Environment. This space aims to educate children and young people on climate change and global issues, encouraging and empowering them to make a difference to their futures.</p>

    <p>In it, you can explore aspects of solar energy, gain a better understanding of greenhouse gases, see for yourself how global warming affects the planet, discover the impact of global food miles,  feel the effect of earthquakes at differing magnitudes and more. You can even experience an extreme weather event in the shape of a hurricane!</p>

    <p>The centre also includes zones focused on space, chemistry, biomedical science and world issues. </p>

    </div>
    `,
    icon: "science.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "wales/techniquest.jpg",
      },
    ],
    link: {
      text: "Cardiff",
      url: "https://www.google.com/maps/place/Techniquest/@51.462558,-3.1702144,17z/data=!3m2!4b1!5s0x486e034810eb6195:0x495b827c020596b3!4m5!3m4!1s0x486e0348117ac4fb:0xd581720f2f1ace89!8m2!3d51.4624979!4d-3.1685732",
    },
  },

  {
    name: "Groundworks North Wales",
    // position: {
    //   x: 2642,
    //   y: 3199,
    // },
    // transform: {
    //   width: 200,
    //   height: 200,
    // },
    position: {
      x: 1314,
      y: 1600,
    },
    transform: {
      width: 100,
      height: 100,
    },
    website: "https://www.groundworknorthwales.org.uk/",
    content: `
    <div class="intro">
    <p>Groundworks North Wales are an environmental charity making a real difference to thousands of people’s lives across the region as well as challenging climate change.</p>
    <p>With offices in Bangor and Wrexham, they work with individuals and communities across North Wales to improve local green space, reduce waste and to protect the natural environment through grant-funded projects.</p>
    <p>They use these projects to support people facing multiple challenges such as living in isolation or with significant health issues, limited employment prospects, and those who are vulnerable to the economic and environmental uncertainty of today’s society. </p>
    <p>Incredibly every year they deliver hundreds of projects across North Wales creating better neighbourhoods, building skills and job prospects and helping people to live and work in a greener way.</p>

    </div>
    `,
    icon: "eco-growth.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "wales/groundworks.jpg",
      },
    ],
    link: {
      text: "Wrexham",
      url: "https://www.google.com/maps/place/Groundwork+Training/@53.0600346,-3.049485,17z/data=!3m1!4b1!4m5!3m4!1s0x487ac77a5d5ad01d:0xe1441a92c1cf40e!8m2!3d53.0600346!4d-3.0472963",
    },
  },

  {
    name: "Project Blu",
    // position: {
    //   x: 2612,
    //   y: 3900,
    // },
    // transform: {
    //   width: 150,
    //   height: 150,
    // },
    position: {
      x: 1306,
      y: 1950,
    },
    transform: {
      width: 75,
      height: 75,
    },
    website: "https://projectblu.co/",
    content: `
    <div class="intro">
    <p>Project Blu are a pet brand driving positive change within the pet industry.</p>
    <p>The ethical company, which was launched in Dinas Powys, converts a range of polluting materials into top-quality pet accessories including beds, collars, leashes and harnesses. The raw materials include ocean-bound plastic, discarded fishing nets, recycled leather and textiles.</p>
    <p>Most recently the team has developed the first range of ‘eco-leather’ pet products that are made by converting discarded apple skins into a super strong and resilient material.  Since officially launching in May 2019, Project Blu has sold over 80,000 products across the globe. Their work has converted over 6 million plastic bottles into sustainable designs.</p>
    
    </div>
    `,
    icon: "pet-clothing.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "wales/projectBlu.jpg",
      },
    ],
    link: {
      text: "Dinas Powys",
      url: "https://www.google.com/maps/place/Dinas+Powys/@51.4299311,-3.2462604,14z/data=!3m1!4b1!4m5!3m4!1s0x486e0371c84fdfff:0xf8d1bc5366de6ca0!8m2!3d51.433799!4d-3.215782",
    },
  },


  /**************************************/
  /**************************************/
  /**************  NI  ******************/
  /**************************************/
  /**************************************/

  {
    name: "Lane 44",
    // position: {
    //   x: 1945,
    //   y: 2443,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 975,
      y: 1220,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://lane44.com/",
    content: `
    <div class="intro">
    <p>Blayne Shaw set up his shaving brand Lane 44 when he saw a gap in the market for a plastic-free and eco-friendly razor. </p>
    <p>According to the Bangor-man a whopping two billion plastic razors end up in landfills and oceans every year causing harm to the natural environment and wildlife.</p>
    <p>“I wanted something to help change that,” he says. “That’s one of our main reasons. When you are talking about plastic razors, encased in plastic, it’s hard to get it close to your skin too.”</p>
    <p>The entrepreneur spent his formative years working in his parents’ own fashion business, and through that experience, learned on the job what’s required to go it alone.</p>
    <p>His company produces eco-friendly, sustainable safety razors across a range of colours as well as vegan friendly skin products. </p>
        
    </div>
    `,
    icon: "eco-beauty.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "NI/lane44.jpg",
      },
    ],
    link: {
      text: "Bangor",
      url: "https://www.google.com/maps/place/Bangor/@54.9840643,-7.1181621,8.03z/data=!4m5!3m4!1s0x48617546a86178ab:0x89bc09bd5b8a569c!8m2!3d54.6541971!4d-5.6730648",
    },
  },

  {
    name: "Senergy Innovations",
    // position: {
    //   x: 1810,
    //   y: 2229,
    // },
    // transform: {
    //   width: 250,
    //   height: 250,
    // },
    position: {
      x: 900,
      y: 1116,
    },
    transform: {
      width: 125,
      height: 125,
    },
    website: "https://www.senergyinnovations.co.uk/",
    content: `
    <div class="intro">
    <p>There are few sectors that are as important to the future of how we work and live than clean and green energy.</p>
    <p>Senergy Innovations chief executive, Christine Boyle MBE, has combined more than 20 years in the commercial roofing sector with specialised expertise, advice, technology and development, to revamp and revolutionise solar power from the company’s base in Belfast.</p>
    <p>The business has modernised and improved on existing solar thermal technology to develop its pioneering Senergy Panels. And according to Christine, solar thermal is around three to four times more efficient than the more common solar PV technology.</p>
    <p>The company’s mission statement perfectly encapsulates its climate commitments, as it envisages a future energy market that delivers a secure supply of the cheapest and cleanest energy to customers globally. And with Christine in charge, you know the leadership is there to reach that goal.</p>
        
    </div>
    `,
    icon: "solarTechnology.svg",
    // brand: "in-logo-black.png",
    category: "pin",
    // categoryname: "lunch, dinner",
    carousel: [
      {
        type: "image",
        content: "NI/senergyInnovations.jpg",
      },
    ],
    link: {
      text: "Belfast",
      url: "https://www.google.com/maps/place/Senergy+Innovations/@54.5999413,-5.9315062,17z/data=!3m1!4b1!4m5!3m4!1s0x4861085693455555:0x7ab115aabe67c4cc!8m2!3d54.5999382!4d-5.9293122",
    },
  },

  

];
