import React from 'react';
import Cat from '../../styles/CategoryStyles';

const Category = ({cat, isActive, clickEvent}) => {
  // Change below line to change file extention
  const format = '.svg';


  const urlSafe = cat.replace(/[^A-Z0-9]+/ig, "_") + format;
  const imgUrl = require(`../../images/catIcons/${urlSafe}`);

  // To View file name categories, uncomment following line,
  // filenames will be logged to the Console:
  // console.log(urlSafe);

  const clickHandler = () => {
    clickEvent(cat);
  }

  return (
    <Cat active={isActive} onClick={clickHandler}>
      <img src={imgUrl} alt={cat} />
      <p>{cat}</p>
    </Cat>
  );
}

export default Category;