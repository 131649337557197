import React from "react";
import ReactModal from "react-modal";
import ModalStyles from "../../styles/ModalStyles";
import Carousel from "../carousel/Carousel";
import SocialIcons from "../../styles/SocialIcons";
import background from "../../images/modalTexture.png";

ReactModal.setAppElement("#root");

const Modal = ({ open, setOpen, html, modalWidth, modalHeight }) => {
  const brandImgUrl = html.brand
    ? require(`../../images/modalBrands/${html.brand}`)
    : "";

  console.log(html);

  const modalStyle = {
    overlay: {
      zIndex: 20,
      backgroundColor: "rgba(0,0,0,0.6)",
    },
    content: {
      width: modalWidth,
      height: modalHeight,
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      // background: "1258a5",
      backgroundImage: `url(${background})` ,
      backgroundColor: "rgba(0,0,0,0.0)",
      border: "1px solid 1258a5",
      overflow: "visible",
      padding: 0,
      // borderRadius: "15px"
    },
  };

  const closeModal = () => {
    setOpen(false);
  };

  const getIcon = (type) => {
    switch (type) {
      case "facebook":
        return (
          <span
            className={`iconify ${type}`}
            data-icon="akar-icons:facebook-fill"
            data-inline="false"
          />
        );
      case "instagram":
        return (
          <span
            className={`iconify ${type}`}
            data-icon="ant-design:instagram-filled"
            data-inline="false"
          />
        );
      case "twitter":
        return (
          <span
            className={`iconify ${type}`}
            data-icon="ant-design:twitter-circle-filled"
            data-inline="false"
          />
        );
      default:
        return <p>No icon found for this social media type</p>;
    }
  };

  return (
    <ReactModal isOpen={open} onRequestClose={closeModal} style={modalStyle}>
      <ModalStyles>
        {html.brand && (
          <div className="brand">
            <img src={brandImgUrl} alt={html.title} />
          </div>
        )}
        <button className="close_button" onClick={closeModal}>
          <i className="material-icons">close</i>
        </button>

        <div className="body">
          <div className="inner_body">
             {html.carouselContents && (
              <Carousel contents={html.carouselContents} />
            )}
            <div className="youtube">
            <div dangerouslySetInnerHTML={{ __html: html.ytvideo }} />
            </div>
            <div className="header">
              <h1>{html.title}</h1>
            </div>
            <div className="address">
              {html.button && (
                <a
                  href={encodeURI(html.button.url)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {html.button.text}
                </a>
              )}
            </div>

            {/* <div className="categoryname">
              <h2>{html.text}</h2>
            </div> */}

            <div className="modal_underline" />

            <div dangerouslySetInnerHTML={{ __html: html.content }} />

            <SocialIcons>
              {html.socials &&
                html.socials.length > 0 &&
                html.socials.map((soc) => (
                  <a
                    href={soc.url}
                    key={soc.name}
                    target="_blank"
                    onClick={() =>
                      window.dataLayer.push({
                        event: "social_icon",
                        socialNetork: soc.name,
                        destinationURL: soc.url,
                      })
                    }
                  >
                    {getIcon(soc.name)}
                  </a>
                ))}
            </SocialIcons>

            {html.website && (
              <div style={{ textAlign: "center" }}>
                <a href={html.website} target="_blank">
                  <button className="visit_website">Discover More</button>
                </a>
              </div>
            )}
          </div>
        </div>
      </ModalStyles>
    </ReactModal>
  );
};

export default Modal;
