import styled from 'styled-components';

const Category = styled.div`
  color: ${props => props.active ? 'red' : 'blue'};
  display: flex;

  img {
    width: 40px;
    height: 40px;
    position: relative;
    bottom: auto;
    padding-left: 0;
    opacity: ${props => props.active ? 1 : 0.3 };
  }
`;

export default Category;