const theme = {
  colours: {
    primary: "#411665",
    secondary: "#00B5BC",
    text: "white",
    background: "#80D5D9",
    // background: "#53B5CA",
  },
  sizing: {
    fontSize: "16px",
    gap: "16px",
  },
  // font: "Jubilat",
};

export default theme;
