import * as React from 'react'
import theme from '../../styles/theme';
import injectSheet from 'react-jss'

const styles = {
  root: {
    cursor: 'pointer',
    fontSize: 40,
    width: 40,
    height: 40,
    lineHeight: '30px',
    textAlign: 'center',
    userSelect: 'none',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: '#ff99aa',
      color: 'white',
      transition: 'transform 1s',
    },
  },
}

const Button = ({ classes, children, ...rest }) => {
  return (
    <div
      className={classes.root}
      {...rest}
    >
      {children}
    </div>
  )
}

export default injectSheet(styles)(Button)
