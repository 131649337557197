import React, { useRef, useState, useEffect } from "react";
import PanZoom from "react-easy-panzoom";
import ZoomControllerUI from "./ZoomControllerUI";
import PadControllerUI from "./PadControllerUI";
import ResetControllerUI from "./ResetControllerUI";
import Header from "../Header";
import CategorySelector from "./CategorySelector";
// import RotationControllerUI from './/RotationControllerUI'



const PanZoomControlUI = (props) => {

  // const [width, setWidth] = useState(window.innerWidth);

  // function handleWindowSizeChange() {
  //     setWidth(window.innerWidth);
  // }
  useEffect(() => {
      center();
      // window.addEventListener('resize', handleWindowSizeChange);
      // return () => {
      //     window.removeEventListener('resize', handleWindowSizeChange);
      // }
  }, []);
  
  // let isMobile = (width <= 425);


  const { zoomInSpeed, zoomOutSpeed, ...rest } = props;
  const panZoom = useRef(null);

  const preventPan = (e, x, y) => {
    if (e.target.classList[0] === "mappoint") return true;
    return false;
  };

  // const preventPan = (e, x, y) => {
  //   if (e.target.classList[0] === "mappoint") return true;
  //   return false;
  // };

  function onZoomIn() {
    panZoom.current && panZoom.current.zoomIn(zoomInSpeed);
  }

  function onZoomOut() {
    panZoom.current && panZoom.current.zoomOut(zoomOutSpeed);
  }

  function moveByRatio(x, y) {
    panZoom.current && panZoom.current.moveByRatio(x, y);
  }

  function center() {
    panZoom.current && panZoom.current.autoCenter();
  }

  function reset() {
    panZoom.current && panZoom.current.reset();
  }

  // function rotateClockwise() {
  //   panZoom.current && panZoom.current.rotate(prevAngle => prevAngle + 10)
  // }

  // function rotateCounterClockwise() {
  //   panZoom.current && panZoom.current.rotate(prevAngle => prevAngle - 10)
  // }

  return (
    <div style={{ position: "relative", height: "100%"}} className="panZoomMap">
      {/* {isMobile && (<PanZoom
        ref={panZoom}
        style={{ height: "100%", overflow: "hidden" }}
        minZoom={0.085}
        maxZoom={0.6}
        autoCenter
        preventPan={preventPan}
        realPinch={true}
      >
        {props.children}
      </PanZoom>)}
      {!isMobile && ( */}
      <PanZoom
        ref={panZoom}
        style={{ height: "100%", overflow: "hidden" }}
        minZoom={0.2}
        maxZoom={1}
        autoCenter
        autoCenterZoomLevel={0.4}
        preventPan={preventPan}
        realPinch={true}
      >
        {props.children}
      </PanZoom>
      {/* )} */}

      <div style={{ position: "absolute", right: -4, top: 0, zIndex: 11 }}>
        <PadControllerUI moveByRatio={moveByRatio} />
      </div>
      <Header>
        <div style={{ position: "absolute", left: 10, top: 140, zIndex: 1 }}>
          <ZoomControllerUI onZoomIn={onZoomIn} onZoomOut={onZoomOut} />
        </div>
        <div style={{ position: "absolute", left: 10, top: 235 }}>
          <ResetControllerUI reset={reset} center={center} />
        </div>
        <CategorySelector
          categories={props.categories}
          activeCategories={props.activeCategories}
          setActiveCategories={props.setActiveCategories}
          defaults={props.defaultCats}
        />
      </Header>
    </div>
  );
};

export default PanZoomControlUI;
