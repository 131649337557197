import styled from "styled-components";

const PointStyles = styled.div`
  position: absolute;
  top: ${(props) => props.stTranslate.y}px;
  left: ${(props) => props.stTranslate.x}px;
  width: ${(props) => props.stTransform.width}px;
  height: ${(props) => props.stTransform.height}px;
  display: ${(props) => (props.shouldDisplay ? "block" : "none")};

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.4s;
  }

  img:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transition: transform 0.6s;
    cursor: pointer;
  }

  img#intro-panel-header:hover {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  div {
    position: relative;
  }
`;

export default PointStyles;
