import React from "react";
import Category from "./Category";
import CategorySelectorStyles from "../../styles/CategorySelectorStyles";

const CategorySelector = ({
  categories,
  activeCategories,
  setActiveCategories,
  defaults,
}) => {
  const handleSelectAll = () => {
    if (categories.length === activeCategories.length) {
      setActiveCategories(defaults);
    } else {
      setActiveCategories(categories);
    }
  };

  const CategoryClickHandler = (cat) => {
    if (activeCategories.indexOf(cat) !== -1) {
      const newCats = activeCategories.filter((cur) => cur !== cat);
      setActiveCategories(newCats);
    } else {
      setActiveCategories([...activeCategories, cat]);
    }
  };

  return (
    <CategorySelectorStyles>
      {/* <h2>filter</h2>
      <button className="selectall" onClick={handleSelectAll}>
        Show All {categories.length === activeCategories.length ? "Yes" : "No"}
      </button> */}
      {categories.map((cat) => (
        <Category
          cat={cat}
          key={cat}
          isActive={activeCategories.indexOf(cat) !== -1}
          clickEvent={CategoryClickHandler}
        />
      ))}
    </CategorySelectorStyles>
  );
};

export default CategorySelector;
