import styled from "styled-components";

const MapStyles = styled.div`
  // width: 5583px;
  // height: 4893px;  
  width: 2791px;
  height: 2441px;
  /* width: 11164px;
  height: 9785px; */
  background-image: url("${(props) => props.bgImg}");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  -webkit-user-drag: none;
  cursor: grab;
  /* top: 1370px;
  right: 240px; */
  /* margin-top: 4844px; */
  margin-left: 400px;

  &:active {
    cursor: grabbing;
  }

  /* @media screen and (max-width:768px){
    margin-top: 2444px;
  } */
`;

export default MapStyles;
