import styled from "styled-components";

const CategorySelectorStyles = styled.div`
  margin-top: 300px;
  overflow: hidden;

  @media screen and (max-height: 880px) and (min-width: 600px) {
    height: 70vh;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 80px;
    margin-left: 10px;
    height: 630px;
  }
  @media screen and (max-height: 667px) {
    height: 480px;
  }
  @media screen and (max-height: 568px) {
    height: 380px;
  }

  h2 {
    font-family: ${(props) => props.theme.font};
    font-size: 16px;
    color: white;
    margin-left: 10px;
    /* text-align: center; */
  }
  button.selectall {
    margin-top: 10px;
    margin-left: 10px;
    padding: 7px;
    margin-bottom: 20px;
    background: #ffe604;
    color: 1258a5;
    border: 2px solid 1258a5;
    border-radius: 5px;
    transition-duration: 0.4s;
    outline: none;
    :hover {
      background-color: white;
    }
  }
  p {
    font-family: open-sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    padding-left: 7px;
    text-transform: uppercase;
    display: none;
  }
  img {
    padding-left: 10px;
    padding-bottom: 10px;
  }
`;

export default CategorySelectorStyles;
