import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const ModalCarousel = ({ contents }) => {
  return contents.length === 1 ? (
    <div className='singleImage'>
      <img src={require(`../../images/modalImages/${contents[0].content}`)} alt="" />
    </div>
  ) : (
    <Carousel>
      {
        contents.map((slide, index) => {
          if(slide.type === 'text' || slide.type === 'video') {
            return (
              <div key={index} dangerouslySetInnerHTML={{__html: slide.content}} />
            )
          }
          if(slide.type === 'image') {
            const imgUrl = require(`../../images/modalImages/${slide.content}`);
            return (
              <div key={index}>
                <img src={imgUrl} alt="" />
              </div>
            )
          }
        })
      }
    </Carousel>
  )
}

export default ModalCarousel;